// import { TableComponent, ShoppingListSubTable } from '../../../components/table/Table';
import { columns, options } from '../../../components/table/files/ShoppingListData';
import { toast } from 'react-toastify';
import { BrowserRouter as Route, useParams } from 'react-router-dom';
import noData from '../../../assets/images/nodata.svg';
import { Accordion } from '../../../components/accordion/Accordion';
import { BasicModal } from '../../../components/modal/BasicModal';
import { BasicForm, InputMainContainer, SelectContainer } from '../../../components/Form/BasicForm'
import {AiOutlineLoading3Quarters} from 'react-icons/ai';
import './style.scss';
import { useDarkMode } from '../../../hooks/useDarkMode';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import { TotalValuesSLType, IShoppingListData, ShoppingListProducts} from "../../../types/Types";
import { useState, State, Downgraded } from '@hookstate/core';
import axios from 'axios';
import { ErrorScreen } from '../../../components/ErrorScreen/ErrorScreen';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useHandleErrors } from '../../../hooks/useHandleErrors';
import { useEffect } from 'react';
import VirtualizedGrid from '../../../components/table/VirtualizedGrid';
import Box from '@mui/material/Box';
import { BugReportModal } from '../../../components/modal/BugReportModal';
import { ModalConfirm } from '../../../components/modal/ModalConfirm';
import { useModal } from '../../../hooks/useModal';

const FONT_SIZE = "clamp(1.2rem, 1.2vw, 1.6rem)";

const CaptionsShoppingList = {
    title: 'Legendas Lista de Compras',
    data: {
        colors: {
            'À Comprar (Possuem o Status: À Comprar)': "#fff",
            'Comprado (Possuem o Status: Comprado)': "#b2ffbb",
            'Rejeitado (Possuem o Status: Rejeitado)': "#c8d6e5",
        },
        tags: {}
    }
}
const BaseInput = styled(TextField)({
    '& .MuiInput-input':{
      fontSize: FONT_SIZE,
      minWidth: '350px',
      width: '100%',
    },
    '& label':{
      fontSize: FONT_SIZE,

    }
  });

  interface addItemInputs{
      sku: string | undefined,
      title: string | undefined,
      qty: number | undefined
  }
  interface BlingData{
    modelo: string,
    codigo: string,
    descricao: string,
    id: string,
    _id: string
}

export const ShoppingList = (props:any) =>{
    document.title = "Lista de Compras - MeLiBeat";
    const handleValidateStatus = useHandleErrors();

    let { id } = useParams<any>();

    const token: string | undefined = localStorage.getItem('token') || undefined;
    const axs = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: { 'Authorization': `Bearer ${token}` }
    })
    const fetchBling = (query:any=undefined) => axs.get<BlingData[]>('/bling/products/relation').then((r: any) => {
        return r.data
    }).catch((err: any)=>{
        return []
    })

    const fetchResource = (query:any=undefined) => axs.get<IShoppingListData[]>('/shoppinglist/'+id, {params: query}).then((r: any) => {
        handleValidateStatus(r)
        return proccessData(r.data)
    }).catch((err: any)=>{
        handleValidateStatus(err.response)
        return []
    })

    const data: State<ShoppingListProducts[]> = useState<ShoppingListProducts[]>([]);
    const blingData: State<BlingData[]> = useState<BlingData[]>([]);

    const FinalValue: State<TotalValuesSLType> = useState([] as TotalValuesSLType)
    const RealValue: State<number> = useState(0 as number)
    const ProjValue: State<number> = useState(0 as number)
    const CreditValue: State<number> = useState(0 as number)
    const showModal: State<boolean> = useState(false as boolean);
    const showAddModal: State<boolean> = useState(false as boolean);
    const buyingStatus: State<string> = useState('' as string);
    const idToChangeStatus: State<string | undefined> = useState('' as string | undefined)
    const finished: State<boolean> = useState(false as boolean);
    const {DarkMode, } = useDarkMode();
    const {showHideModal} = useModal();


    const addItemInputs: State<addItemInputs> = useState<addItemInputs>({
        sku: undefined,
        title: undefined,
        qty: undefined
    })


    const modalColumns = [
        { 
        headerName: "Cód.",
        field: "idSupplier", 
        flex: 0.5,
        disableColumnMenu: true,
        headerAlign: 'center',
        type: 'string',
        },
        { 
        headerName: "Fornecedor",
        field: "supplier", 
        flex: 2,
        disableColumnMenu: true,
        headerAlign: 'center',
        type: 'string',
        renderCell: (rowData: any) => {
            return (
                <Tippy content={rowData.row.supplier}>
                    <main style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                        <span>
                            {rowData.row.supplier}
                        </span>
                    </main>
                </Tippy>
            )
        }
        },
        { 
        headerName: "Saldo Crédito",
        field: "creditBalance", 
        flex: 1,
        disableColumnMenu: true,
        headerAlign: 'center',
        type: 'number',
        renderCell: (rowData: any) => {
            return (
            rowData.row.creditBalance.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
            )
        }
        },
        { 
        headerName: "Último Custo",
        field: "last_cost", 
        flex: 0.5,
        disableColumnMenu: true,
        headerAlign: 'center',
        type: 'number',
        renderCell: (rowData: any) => {
            return (
            rowData.row.last_cost.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
            )
        }
        },
        { 
            headerName: "Custo Atual",
            field: "actual_cost", 
            flex: 0.5,
            disableColumnMenu: true,
            headerAlign: 'center',
            type: 'number',
            editable: finished.get() ? false: true,
            renderCell: (rowData: any) => {
                return (
                    <span style={{textDecoration: 'underline dotted'}}>
                        {rowData.row.actual_cost.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                    </span>
                )
            }
        },
        { 
            headerName: "Custo Total Estimado",
            field: "total_projected", 
            flex: 1,
            disableColumnMenu: true,
            headerAlign: 'center',
            type: 'number',
            renderCell: (rowData: any) => {
                return (
                    rowData.row.total_projected.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                )
            }
        },
        { 
            headerName: "Custo Total Orçado",
            field: "total_real", 
            flex: 1,
            disableColumnMenu: true,
            headerAlign: 'center',
            type: 'number',
            renderCell: (rowData: any) => {
                return (
                    rowData.row.total_real.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                )
            }
        },
        { 
            headerName: "Total Pagamento a Vista Estimado",
            field: "total_payment_real", 
            flex: 1,
            disableColumnMenu: true,
            headerAlign: 'center',
            type: 'number',
            renderCell: (rowData: any) => {
                let result = rowData.row.creditBalance <= 0 ? rowData.row.total_projected : (rowData.row.total_projected - rowData.row.creditBalance)
                result = result < 0 ? 0 : result
                return (
                    result.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                )
            }
        },
        { 
            headerName: "Total Pagamento a Vista Orçado",
            field: "total_payment_projected", 
            flex: 1,
            disableColumnMenu: true,
            headerAlign: 'center',
            type: 'number',
            renderCell: (rowData: any) => {
                let result = rowData.row.creditBalance <= 0 ? rowData.row.total_real : (rowData.row.total_real - rowData.row.creditBalance)
                result = result < 0 ? 0 : result
                return (
                    result.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                )
            }
        }
    ]

    useEffect(() => {
        data.set(fetchResource())
        blingData.set(fetchBling())
    }, [])

    const handleProccessValues = (values : any)=>{
        let totalValueNew = FinalValue.attach(Downgraded).get();
        if(totalValueNew.length > 0){
            for(let supplier of values){
                let ind = totalValueNew.findIndex((element:any) => element.product_id === supplier.product_id && element.type_value === supplier.type_value)
                if(ind === -1){
                    totalValueNew.push({
                        "product_id": supplier.product_id,
                        "value": supplier.value,
                        "type_value": supplier.type_value
                    })
                }else{
                    totalValueNew[ind].value = supplier.value
                }              
            }
        }else{
            totalValueNew = [...values]
        }

        let proj = totalValueNew.reduce((acc: number, item: any)=>{
            if(item.type_value === "total_projected"){
                return acc + item.value
            }else{
                return acc
            }
        }, 0) 
        let real = totalValueNew.reduce((acc: number, item: any)=>{
            if(item.type_value === "total_real"){
                return acc + item.value
            }else{
                return acc
            }
        }, 0)
        let credit = totalValueNew.reduce((acc: number, item: any)=>{
            if(item.type_value === "creditBalance"){
                return acc + item.value
            }else{
                return acc
            }
        }, 0)

        FinalValue.set(totalValueNew)
        RealValue.set(real)
        ProjValue.set(proj)
        CreditValue.set(credit)
    }

    
    const proccessData = (values: IShoppingListData)=>{
        let processedArray: any = [];
        let auxiliary_array: TotalValuesSLType = []
        for (let item of values.products){
            processedArray.push(item)
            for(let supplier of item.suppliers){
                supplier["qty"] = item.qty
                supplier["product_id"] = item._id;
                supplier["total_projected"] = (supplier.qty !== undefined ? supplier.qty:0) * ( supplier.last_cost !== undefined ?  supplier.last_cost : 0);
                supplier["total_real"] = (supplier.qty !== undefined ? supplier.qty:0) * (supplier.actual_cost !== undefined ? supplier.actual_cost : 0);
            }
            auxiliary_array.push({"value": item.suppliers[0].total_real, "product_id": item.suppliers[0].product_id, "type_value": "total_real"})
            auxiliary_array.push({"value": item.suppliers[0].total_projected, "product_id": item.suppliers[0].product_id, "type_value": "total_projected"})
            auxiliary_array.push({"value": item.suppliers[0].creditBalance, "product_id": item.suppliers[0].product_id, "type_value": "creditBalance"})
        }
        finished.set(values.finished)
        handleProccessValues(auxiliary_array)
        return processedArray
    }

    const handleCellEdit = async(newValue: any)=>{
        if(newValue.value < 0){
            toast.error('Não é possível adicionar valores negativos!');
            return
        }
        if(newValue.field === 'qty'){
            const processedData = {
                "id": newValue.id,
                "qty": newValue.value,
            }
            axs.put<IShoppingListData[]>('/shoppinglist/qty', processedData).then((r: any) => {
                toast.info('Dados Atualizados!');
                data.set(proccessData(r.data))
            }).catch((err: any)=>{
                handleValidateStatus(err.response)
            })
        }else if(newValue.field === 'actual_cost'){
            const dt = data.get().filter((elm: any)=> elm.suppliers.some((supplier: any)=> supplier._id === newValue.id))
            const supplier = dt[0].suppliers.filter((elm: any)=> elm._id === newValue.id)
            const processedData = {
                "id": id,
                "id_product": supplier[0].product_id,
                "id_supplier": newValue.id,
                "cost": newValue.value,
            }
            axs.put<IShoppingListData[]>('/shoppinglist/cost', processedData).then((r: any) => {
                toast.info('Dados Atualizados!');
                data.set(proccessData(r.data))
            }).catch((err: any)=>{
                handleValidateStatus(err.response)
            })
        }
    }

    const handleOpenBuyOperationModal = (value: IShoppingListData)=>{
        showModal.set(true);
        idToChangeStatus.set(value._id);
    }
    const postBuyOperation = async(evt: React.BaseSyntheticEvent)=>{
        evt.preventDefault();
        const processedData = {
            'id': idToChangeStatus.get(),
            'status': buyingStatus.get()
        }
        axs.put<IShoppingListData[]>('/shoppinglist/status', processedData).then((r: any) => {
            toast.info('Dados Atualizados!');
            data.set(proccessData(r.data))
        }).catch((err: any)=>{
            handleValidateStatus(err.response)
        })
        showModal.set(false)

    }

    const handleAddNewItem = ()=>{
        if(addItemInputs.title.value === undefined){
            toast.info('O campo Título deve estar preenchido!');
            return
        }
        if(addItemInputs.qty.value === undefined){
            toast.info('O campo Quantidade deve estar preenchido!');
            return
        }
        const processedData = {
            sku: addItemInputs.sku.value ? addItemInputs.sku.value : '',
            title: addItemInputs.title.value,
            qty: addItemInputs.qty.value,
        }
        addItemInputs.set({
            "sku": undefined,
            "title": undefined,
            "qty": undefined,
        })
        showAddModal.set(false);
        axs.put<IShoppingListData[]>(`/shoppinglist/item/${id}`, processedData).then((r: any) => {
            toast.info('Item adicionado!');
            data.set(fetchResource())
        }).catch((err: any)=>{
            handleValidateStatus(err.response)
        })
    }

    
    const confirmDelete = async(value: boolean, itemId: string)=>{
        if(value){
            const returnPost = await axs.delete<IShoppingListData>(`/shoppinglist/item/${id}`, {params: {"itemId": itemId}});
            if(returnPost.status === 200){
                data.set(fetchResource())
                toast.success("Item deletado!");
            }else{
                toast.error("Não foi possível deletar!");
            }
        }else{
            toast.info("Você escolheu não deletar!");
        }
    }

    if(data.promised){ //Enquanto for promisse ou estiver carregando
        return (
            <>
                <div className={`wrapper ${DarkMode ? 'dark-mode-wrapper' : ''}`}> 
                    <div className="content-wrapper">
                        <main className="main-content container-fluid">
                            <div className="loading">
                                <AiOutlineLoading3Quarters className="loading-icon" />
                            </div>
                        </main>
                    </div>
                </div>
            </>
        )
    }

    if (data.error) { // Quando o promise dar reject
        return (<ErrorScreen />)
    }

    return(
        <>
            {
                showModal.get() === true ?
                <BasicModal closeFunction={()=> showModal.set(false)}>
                    <BasicForm SubmitFunction={postBuyOperation}>
                        <InputMainContainer>
                            <SelectContainer
                                label="Status de Compra"
                                id="status"
                                name="status"
                                required={true}
                                options={
                                    {'data': [{'value': '', 'text': ''}, {'value': 'to_buy', 'text': 'A Comprar'}, {'value': 'buyed', 'text': 'Comprado'}, {'value': 'rejected', 'text': 'Rejeitado'}]}
                                }
                                value={undefined}
                                onChange={(value: any)=> buyingStatus.set(value['status'])}
                            />
                        </InputMainContainer>

                        <InputMainContainer>
                            <button type="submit">Alterar Status</button>
                        </InputMainContainer>
                    </BasicForm>
                </BasicModal>
                :
                null
            }

            {
                showAddModal.get() === true ?
                <BasicModal closeFunction={()=> {
                    showAddModal.set(false);
                    addItemInputs.set({
                        "sku": undefined,
                        "title": undefined,
                        "qty": undefined,
                    })
                }}>
                    <Paper style={{ width: '100%', padding: "5rem", display: 'flex', flexDirection: 'column', gap: '2rem', alignItems: 'center', justifyContent: 'center'}}>
                        <BaseInput
                         label="SKU" 
                         variant="standard" 
                         type='text'
                         value={addItemInputs.sku.value}
                         onChange={(evt: React.BaseSyntheticEvent)=>{
                            const sku_value = evt.target.value;
                            const blingDataAux = [...blingData.attach(Downgraded).get()];
                            let idx = blingDataAux.findIndex((t:any)=> (t.codigo === sku_value))
                            if(idx > -1){
                                addItemInputs.title.set(`${blingDataAux[idx].descricao} - ${blingDataAux[idx].modelo}`);
                            }
                            addItemInputs.sku.set(sku_value);
                         }}
                        />
                        <BaseInput
                         label="Título" 
                         variant="standard" 
                         type='text'
                         key={addItemInputs.sku.value}
                         value={addItemInputs.title.value}
                         onChange={(evt: React.BaseSyntheticEvent)=>{
                            addItemInputs.title.set(evt.target.value);
                         }}
                        />
                         <BaseInput
                         label="Quantidade" 
                         variant="standard" 
                         type='number'
                         value={addItemInputs.qty.value}
                         onChange={(evt: React.BaseSyntheticEvent)=>{
                            addItemInputs.qty.set(evt.target.value);
                         }}
                        />
                        <Button variant="contained" style={{fontSize: FONT_SIZE, width: '100%'}} color='primary' onClick={handleAddNewItem}>
                            Adicionar
                        </Button>
                    </Paper>
                </BasicModal>
                :
                null
            }

            <div className={`wrapper ${DarkMode ? 'dark-mode-wrapper' : ''}`}>
                <ModalConfirm />
                <BugReportModal />
                <div className="content-wrapper">
                    <main className="main-content container-fluid">
                        <Accordion title={CaptionsShoppingList.title} data={CaptionsShoppingList.data} />
                        {
                            data.length > 0 ?
                            <>
                                {
                                    FinalValue.get() ?
                                    <div className="totalValuesContainer">
                                        <div className="real_container">
                                            <div>
                                                <h3>Custo Total Estimado:&nbsp;
                                                    <strong>{ProjValue.get().toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong>
                                                </h3>
                                            </div>
                                            <div>
                                                <h3>Total Pagamento a Vista Estimado:&nbsp;
                                                    <strong>{(CreditValue.get() <= 0 ? ProjValue.get() : (ProjValue.get() - CreditValue.get() <=0 ? 0 : ProjValue.get() - CreditValue.get())).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong>
                                                </h3>
                                            </div>
                                        </div>

                                        <div className="projected_container">
                                            <div>
                                                <h3>Custo Total Orçado:&nbsp;
                                                    <strong>{RealValue.get().toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong>
                                                </h3>
                                            </div>
                                            <div>
                                                <h3>Total Pagamento a Vista Orçado:&nbsp;
                                                    <strong>{(CreditValue.get() <= 0 ? RealValue.get() : (RealValue.get() - CreditValue.get() <= 0 ? 0 : RealValue.get() - CreditValue.get())).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                                <Paper style={{ width: '100%', padding: "2rem 0", display: 'flex', alignItems: 'center', justifyContent: 'flex-end', backgroundColor: 'transparent', boxShadow:'none'}}>
                                    <Button variant="contained" style={{fontSize: FONT_SIZE}} color='primary' onClick={(evt: React.BaseSyntheticEvent)=> showAddModal.set(true)}>
                                        Adicionar Item
                                    </Button>
                                </Paper>
                                <VirtualizedGrid 
                                    rows={data.attach(Downgraded).get()}
                                    columns={columns}
                                    options={options}
                                    title="Lista de Compras"
                                    defaultSort={{field: 'creditBalance', direction: 'desc'}}
                                    actions={[
                                        {icon: 'settings', title: 'Mais Ações', method: (row:any)=> handleOpenBuyOperationModal(row)}, 
                                        {icon: 'delete', title: 'Deletar Item', method: (evt: any, row:any)=> showHideModal({show: true, title: "Quer mesmo deletar esse item?", execute: (confirm: boolean)=> confirmDelete(confirm, row._id)})}, 
                                    ]}
                                    onCellEdit={(value: any)=> handleCellEdit(value)}
                                    detailPanel={({ row }: {row:any}) => 
                                        <Box sx={{ margin: 1 }}>
                                            <VirtualizedGrid 
                                                SelectFirstRow={true}
                                                rows={row['suppliers']}
                                                columns={modalColumns}
                                                options={options}
                                                title={"Fornecedores"}
                                                tableHeight= 'auto'
                                                defaultSort={{field: 'actual', direction: 'desc'}}
                                                hidePagination={true}
                                                hideSearchAndTitle={false}
                                                onCellEdit={(value: any)=> {
                                                    handleCellEdit(value);
                                                }}
                                                onRowClick={(param: any)=> {
                                                    if(param.sameRow){
                                                        handleProccessValues(
                                                            [
                                                                {"value": 0, "product_id": param.row.product_id, "type_value": "total_real"}, 
                                                                {"value": 0, "product_id": param.row.product_id, "type_value": "total_projected"}
                                                            ]
                                                        )
                                                    }else{
                                                        handleProccessValues(
                                                            [
                                                                {"value": param.row.total_real, "product_id": param.row.product_id, "type_value": "total_real"}, 
                                                                {"value": param.row.total_projected, "product_id": param.row.product_id, "type_value": "total_projected"}
                                                            ]
                                                        )
                                                    }
            
                                                }}
                                            />
                                        </Box>
                                    }
                                />
                            </>
                            :
                            <div className="img-container">
                                <img src={noData} alt="Não foi possível renderizar o gráfico por falta de dados." />
                                <h4>Não foi possível renderizar os gráficos por falta de dados.</h4>
                            </div>
                        }
                    </main>

                </div>
            </div>
        </>
    )
}