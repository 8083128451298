import { columns, options } from '../../../components/table/files/PurchaseTrackingData';
import { toast } from 'react-toastify';
import noData from '../../../assets/images/nodata.svg';
import { PopOverMenuModal } from '../../../components/modal/PopOverMenuModal';
import {FiExternalLink} from 'react-icons/fi';
import { GridModal } from '../../../components/modal/GridModal';
import moment from 'moment';
import './style.scss';
import { PurchaseTrackingType, IPopOverMenuModalData, IPurchaseTracking, ObjetoVazio } from "../../../types/Types"
import { useDarkMode } from '../../../hooks/useDarkMode';
import VirtualizedGrid from '../../../components/table/VirtualizedGrid';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import axios from 'axios';
import { useState , State, Downgraded } from '@hookstate/core';
import { Untracked } from '@hookstate/untracked';
import { ErrorScreen } from '../../../components/ErrorScreen/ErrorScreen';
import React, { useEffect } from 'react';
import { BasicModal } from "../../../components/modal/BasicModal";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { useHandleErrors } from '../../../hooks/useHandleErrors';
import { Filters } from '../../../components/Filters/Filters';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Popper from '@mui/material/Popper';
import { Loading } from '../../../components/Loading/Loading';
import { BugReportModal } from '../../../components/modal/BugReportModal';

const INPUT_FONT_SIZE = 'clamp(1.2rem, 1.2vw, 1.6rem)';

const StyledPopper = styled(Popper)({
    "& .MuiAutocomplete-listbox": {
      '& li':{
        fontSize: 'clamp(1rem, 1vw, 1.1rem)',
      }
    }
});

const modalColumns = [
    { 
      headerName: "Produto",
      field: "product", 
      type: "string",
      disableColumnMenu: true,
      headerAlign: 'center',
      flex: 3,
      renderCell: (rowData: any) => {
        return (
            <Tippy content={rowData.row.product}>
                <main style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                    <strong>{rowData.row.product}</strong>
                </main>
            </Tippy>
        )
      }
    },
    { 
        headerName: "Quantidade",
        field: "qty", 
        type: "number",
        disableColumnMenu: true,
        headerAlign: 'center',
        flex: 1,
        renderCell: (rowData: any) => {
            return (
                <strong>{rowData.row.qty}</strong>
            )
        }
    },
    { 
        headerName: "Valor",
        field: "cost", 
        type: "number",
        disableColumnMenu: true,
        headerAlign: 'center',
        flex: 1,
        renderCell: (rowData: any) => {
            return (
                <strong>{rowData.row.cost.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong>
            )
        }
    },
    { 
        headerName: "Valor com IPI",
        field: "costWithIpi", 
        type: "number",
        disableColumnMenu: true,
        headerAlign: 'center',
        flex: 1,
        renderCell: (rowData: any) => {
            return (
                <strong>{rowData.row.costWithIpi.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong>
            )
        }
    },
    { 
        headerName: "IPI",
        field: "ipi", 
        type: "number",
        disableColumnMenu: true,
        headerAlign: 'center',
        flex: 1,
        renderCell: (rowData: any) => {
            return (
                <strong>{rowData.row.ipi}%</strong>
            )
        }
    }
]
const modalOptions = {
    palette:{
      tableHeader: {
        main: '#F7CE3E',
        dark: '#ac902b',
        light: '#f8d764',
        contrastText: '#000'
      },
    },
    alternate: true,
}


interface BlingData{
    modelo: string,
    codigo: string,
    descricao: string,
    id: string,
    _id: string
}

interface MergedSkus{
    skuRef: string,
    id: string
}

export const PurchaseTracking = (props:any) =>{
    document.title = "Acompanhamento de Compras - MeliBeat";
    const token: string | undefined = localStorage.getItem('token') || undefined;
    const axs = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: { 'Authorization': `Bearer ${token}` }
    })
    const handleValidateStatus = useHandleErrors();

    const fetchResource = (query:any=undefined) => axs.get<PurchaseTrackingType[]>('/purchasetracking/', {params: query}).then((r: any) => {
        handleValidateStatus(r)
        return r.data
    }).catch((err: any)=>{
        handleValidateStatus(err.response)
        return []
    })
    const fetchBling = (query:any=undefined) => axs.get<BlingData[]>('/bling/products/relation', {params: query}).then((r: any) => {
        handleValidateStatus(r)
        return r.data
    }).catch((err: any)=>{
        return []
    })
    const data: State<PurchaseTrackingType[]> = useState<PurchaseTrackingType[]>([]);
    const blingData: State<BlingData[]> = useState<BlingData[]>([]);

    const deliveredValue: State<string> = useState<string>('');
    const undeliveredValue: State<any> = useState<string>('');
    const totalGrossWeight: State<string> = useState<string>('');
    deliveredValue.attach(Untracked);
    undeliveredValue.attach(Untracked);
    totalGrossWeight.attach(Untracked);
    // const [filters, isFiltering, isReseting, executeSetNewFilter, executeFilter, executeStopFilter, executeResetFilter] = useFilter();
    // const [executeGet, , executePut, , result, accessDenied] = useRequest();
    const menuPosition: State<object> = useState<object>({'x': Number, 'y': Number});
    const showPopOverMenuModal: State<boolean> = useState<boolean>(false);
    const popOverMenuModalData: State<IPopOverMenuModalData> = useState<IPopOverMenuModalData>({
        "senderAddress": {
            "street": '',
            "number": '',
            "district": '',
            "city": '',
            "uf": '',
            "cep": ''
        },
        "_id": '',
        "number": '',
        "serie": '',
        "cnpjSender": '',
        "sender": '',
        "date": '',
        "totalValue": undefined,
        "chaveAcesso": '',
        "xml": '',
        "danfe": '',
        "cnpjCarrying": '',
        "trackLinkCarrying": '',
        "carrying": '',
        "products": [
            {
                "_id": '',
                "product": '',
                "code": '',
                "qty": undefined,
                "cost": undefined,
                "costWithIpi": undefined,
                "ipi": undefined,
                "inf": ''
            }
        ],
        "observations": '',
        "delivered": false,
        "finished": false,
        "__v": undefined,
        "deliveredDate": '',
        "deliveryTime": '',
        "grossWeight": undefined,
        "netWeight": undefined,
        "volume": undefined,
        "tableData": {
            "id": 0
        }
    });
    const showTableModal: State<boolean> = useState<boolean>(false);
    const showBlingTableModal: State<boolean> = useState<boolean>(false);
    const mergedSkus: any = useState([])
    const {DarkMode, } = useDarkMode();
    const filterValues: ObjetoVazio = useState<ObjetoVazio>({"finished": undefined})
    const BlingColumns = [
        { 
          headerName: "Produto",
          field: "product", 
          type: "string",
          disableColumnMenu: true,
          headerAlign: 'center',
          flex: 3,
          renderCell: (rowData: any) => {
            return (
                <Tippy content={rowData.row.product}>
                    <main style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                        <strong>{rowData.row.product}</strong>
                    </main>
                </Tippy>
            )
          }
        },
        { 
            headerName: "Sku Bling",
            field: "bling_sku", 
            type: "string",
            disableColumnMenu: true,
            headerAlign: 'center',
            flex: 1,
            renderCell: (rowData: any) => {
                const filteredValue: BlingData[] = blingData.attach(Downgraded).get().filter((item: BlingData)=> rowData.row.skuRef === item.codigo);
                return (
                    <Autocomplete
                        style={{width: '100%', display: 'flex'}}
                        id="skus"
                        defaultValue={filteredValue.length > 0 ? filteredValue[0] : undefined}
                        className="skus-select"
                        options={blingData.attach(Downgraded).get()}
                        getOptionLabel={(option: any) => `${option.descricao} - ${option.modelo}`}
                        disableClearable
                        renderOption={(props, option) => (
                            <li {...props}>
                                {`${option.codigo} - ${option.descricao} - ${option.modelo}`}
                            </li>
                            )}
                        renderInput={(params) => <TextField variant="standard" {...params} placeholder="SKU" />}
                        PopperComponent={(props: any)=> <StyledPopper {...props} style={{ width: "fit-content", padding: "1rem", display: "flex", flexDirection: "column", gap: '1rem' }} placement='bottom-start' />}
                        onChange={(evt: React.BaseSyntheticEvent, value: any)=> {
                            const sku_value = value.codigo;
                            const id = rowData.row._id;
                            // console.log({id: id, skuRef: sku_value})
                            const idx = mergedSkus.get().findIndex((it: any) => it.id === id)
                            // console.log(idx)
                            if(idx > -1){
                                mergedSkus[idx].skuRef.set(sku_value)
                            } else{
                                mergedSkus.merge((p: any) => (
                                    p.push({id: id, skuRef: sku_value})  
                                ))
                            }
                            // console.log(mergedSkus)
                        }}
                    />
                )
            }
        },
    ]

    useEffect(() => {
        data.set(fetchResource({"finished": false}))
        blingData.set(fetchBling())
    }, [])

    const handlePopOverMenuModal = async(evt: React.BaseSyntheticEvent, origin: string)=>{
        showPopOverMenuModal.set(false);
        if(origin === "products"){
            showTableModal.set(true)
        }else if(origin === "tracking"){
            return
        }else if(origin === "delivered"){
            await axs.put<PurchaseTrackingType[]>('/purchasetracking/delivered/'+popOverMenuModalData._id.value, {}).then((r: any) => {
                if(r.status === 200){
                    toast.info('Compra marcada como ENTREGUE!');
                    data.set(fetchResource({"finished": false}))
                }
            }).catch((err: any)=>{
                handleValidateStatus(err.response)
            })
        }else if(origin === "finish"){
            if(popOverMenuModalData.products.attach(Downgraded).get() && popOverMenuModalData.products.attach(Downgraded).get().some((item: any)=> item.skuRef === undefined)){
                toast.info('Não foi possível finalizar esse item. Verifique se ele possui SKUS vinculados com o Bling e tente novamente!');
                return
            }
            await axs.put<PurchaseTrackingType[]>('/purchasetracking/finished/'+popOverMenuModalData._id.value, {}).then((r: any) => {
                if(r.status === 200){
                    toast.info('Compra marcada como FINALIZADA!');
                    data.set(fetchResource({"finished": false}))
                }
            }).catch((err: any)=>{
                handleValidateStatus(err.response)
            })
        }
    }

    const handleModal = (event: any, value: any)=>{
        popOverMenuModalData.set(value)
        menuPosition.set({x: (event.pageX-240), y: event.pageY});
        showPopOverMenuModal.set(true);
    }

    const handleCellEdit = async(new_value: any)=>{
        const raw_data = data.attach(Downgraded).get();
        const cellChanged = raw_data?.filter((item: any)=> item._id === new_value.id);
        if(cellChanged !== undefined){
            if(new_value.field === 'observations'){
                await axs.put<PurchaseTrackingType[]>('/purchasetracking/obs/'+new_value.id, {'observations': new_value.value}).then((r: any) => {
                    if(r.status === 200){
                        const resData = r.data;
                        const aux_data = JSON.parse(JSON.stringify(raw_data))
                        aux_data.forEach((item: any)=>{
                            if(item._id === new_value.id){
                                item['observations'] = resData.observations;
                            }
                        })
                        data.set(aux_data);
                        toast.info('Observação sobre a compra adicionada!');
                    }
                }).catch((err: any)=>{
                    handleValidateStatus(err.response)
                })
            }else{
                await axs.put<PurchaseTrackingType[]>('/purchasetracking/deliverytime/'+new_value.id, {'date': new_value.value}).then((r: any) => {
                    if(r.status === 200){
                        const resData = r.data;
                        const aux_data = JSON.parse(JSON.stringify(raw_data))
                        aux_data.forEach((item: any)=>{
                            if(item._id === new_value.id){
                                item['deliveryTime'] = resData.deliveryTime;
                            }
                        })
                        data.set(aux_data);
                        toast.info('Prazo estimado adicionado!');
                    }
                }).catch((err: any)=>{
                    handleValidateStatus(err.response)
                })
            }
        }
    }

    const handleFilter = (filters: any)=>{
        toast.info('Filtrando...')
        showPopOverMenuModal.set(false);
        data.set(fetchResource({"finished": filters.filterFinished === '1' ? true : false}))
    }

    const handleReset = ()=> {
        filterValues.finished.set(undefined)
        data.set(fetchResource({"finished": false}))
    }

    const handleBligProducts = (evt: React.BaseSyntheticEvent)=>{
        showBlingTableModal.set(true);
        showPopOverMenuModal.set(false);
        mergedSkus.set([])
    }

    const handleSave = async () =>{
        if(mergedSkus.length === popOverMenuModalData.products.length){
            const values = mergedSkus.attach(Downgraded).get();
            await axs.put<PurchaseTrackingType[]>('/purchasetracking/ref/'+popOverMenuModalData._id.value, {refs: values}).then((r: any) => {
                if(r.status === 200){
                    toast.info('Dados vinculados com sucesso!');
                    data.set(fetchResource())
                }
            }).catch((err: any)=>{
                handleValidateStatus(err.response)
            })
            showBlingTableModal.set(false);
        }else{
            console.log(mergedSkus.length === popOverMenuModalData.products.length)
            console.log(mergedSkus)
            console.log(popOverMenuModalData.products)
            toast.info('Todos os campos precisam ser preenchidos!');
        }
    }


    data.batch((data) => {
        if (data.promised) return
        if (data.error) return
        else{
            const values = data.attach(Downgraded).get();
            if(data.length <= 0 && data.get() === undefined) return

            const valuesDelivered = values.map((item: any)=> {
                const new_item: IPurchaseTracking = item;
                if(item.delivered === true){
                    return new_item.totalValue
                }else{
                    return 0
                }
            })
            const valuesUndelivered = values.map((item: any)=> {
                const new_item: IPurchaseTracking = item;
                if(item.delivered === false){
                    return new_item.totalValue
                }else{
                    return 0
                }
            })

            const totalWeight = values.reduce(function (acc:number, value:any) { 
                if(!value.finished && value.delivered){
                    return acc + value.grossWeight; 
                }else{
                    return acc + 0
                }
            }, 0);
            const totalValuesDelivered = valuesDelivered.reduce((acc: number, value: any) => acc + value, 0)
            const totalValuesUndelivered = valuesUndelivered.reduce((acc: number, value: any) => acc + value, 0)

            Untracked(deliveredValue).set(totalValuesDelivered.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}))
            Untracked(undeliveredValue).set(totalValuesUndelivered.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}))
            Untracked(totalGrossWeight).set(totalWeight.toLocaleString('pt-br',{maximumFractionDigits: 2}))
        }
    })
    
    return(
        <>
            {
                showPopOverMenuModal.get() ?
                    <PopOverMenuModal position={menuPosition.attach(Downgraded).get()} closeFunction={()=> showPopOverMenuModal.set(false)}>
                        <button onClick={(evt: React.BaseSyntheticEvent)=> handlePopOverMenuModal(evt, 'products')}>Produtos</button>
                            <a href={popOverMenuModalData.trackLinkCarrying.value} onClick={(evt: React.BaseSyntheticEvent)=> handlePopOverMenuModal(evt, 'tracking')} target="_blank" rel="noreferrer">
                                <div>Tracking</div><FiExternalLink />
                            </a>
                        <button 
                            className={popOverMenuModalData.delivered.value ? "delivered":''} 
                            onClick={(evt: React.BaseSyntheticEvent)=> handlePopOverMenuModal(evt, 'delivered')}
                            disabled={popOverMenuModalData.delivered.value} 
                        >
                            {popOverMenuModalData.delivered ? <>Pedido Entregue: <div>{moment(popOverMenuModalData.deliveredDate.value).format("DD/MM/YYYY")}</div></>: "Confirmar Entrega"}
                        </button>
                        <button 
                            className={popOverMenuModalData.finished.value ? "finished":''} 
                            disabled={popOverMenuModalData.finished.value} 
                            onClick={(evt: React.BaseSyntheticEvent)=> handlePopOverMenuModal(evt, 'finish')}
                        >
                            {popOverMenuModalData.finished.value ? "Pedido Finalizado" : "Finalizar Compra"}
                        </button>
                        <button 
                            disabled={popOverMenuModalData.finished.value} 
                            onClick={(evt: React.BaseSyntheticEvent)=> handleBligProducts(evt)}
                        >Vincular com o Bling
                        </button>
                    </PopOverMenuModal>
                : null
            }
            {
                showTableModal.get() === true
                ?<GridModal 
                    closeFunction={()=> showTableModal.set(false)} tableTitle="Produtos" 
                    options={modalOptions} 
                    columns={modalColumns} 
                    data={popOverMenuModalData.products.attach(Downgraded).get()} 
                    style={{width: '80%'}}
                    defaultSort={{field: 'product', direction: 'asc'}}
                />
                : null
            }
            {
                showBlingTableModal.get() === true ?
                    <BasicModal closeFunction={()=> showBlingTableModal.set(false)}>
                        <Paper style={{ width: '80vw', height: '95%', padding: "1rem", display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: '2rem'}}>
                            <VirtualizedGrid 
                                rows={popOverMenuModalData.products.attach(Downgraded).get()}
                                columns={BlingColumns}
                                options={options}
                                title="Vínculo SKU Bling"
                                onCellEdit={(value: any)=> handleCellEdit(value)}
                                defaultSort={{field: 'product', direction: 'asc'}}
                            />
                            <Button variant="contained" style={{fontSize: "clamp(1.2rem, 1.2vw, 1.6rem)"}} color='success' onClick={handleSave}>
                                Salvar
                            </Button>
                        </Paper>
                    </BasicModal>
                : null
            }
            <div className={`wrapper  ${DarkMode ? 'dark-mode-wrapper' : ''}`}>
                <BugReportModal />
                <div className="content-wrapper">
                    <main className="main-content container-fluid">
                    <Filters submitFunction={handleFilter} hideFullResetBtn handleReset={handleReset}>
                        <FormControl size='small' variant='outlined' sx={{minWidth: '20rem'}} key={filterValues.finished.get()}>
                            <InputLabel id="finished-label-filter" style={{fontSize: INPUT_FONT_SIZE}}>Finalizado</InputLabel>
                            <Select
                                labelId="finished-label-filter"
                                id="finished-filter"
                                name='filterFinished'
                                value={filterValues.finished.get()}
                                label="Origem"
                                style={{fontSize: INPUT_FONT_SIZE}}
                                required
                                onChange={(evt: SelectChangeEvent)=> filterValues.finished.set(evt.target.value)}
                            >
                                <MenuItem value={''}></MenuItem>
                                <MenuItem value={'1'}>Sim</MenuItem>
                                <MenuItem value={'0'}>Não</MenuItem>
                            </Select>
                        </FormControl>
                    </ Filters>
                        {
                            data.promised ? 
                            <Loading />
                            : data.error ?
                            <ErrorScreen /> : 
                            data ?
                            <>
                                {
                                    deliveredValue.get() !== undefined && undeliveredValue.get() !== undefined && totalGrossWeight.get() !== undefined ?
                                    <div className={`purchase-tracking_totalValuesContainer ${DarkMode ? 'dark-mode-purchase-tracking_totalValuesContainer' : ''}`}>
                                        <div>
                                            <h3>Valor das Notas Entregues:&nbsp;
                                                <strong>{deliveredValue.get()}</strong>
                                            </h3>
                                        </div>

                                        <div>
                                            <h3>Valor das Notas Não Entregues:&nbsp;
                                                <strong>{undeliveredValue.get()}</strong>
                                            </h3>
                                        </div>

                                        <div>
                                            <h3>Peso Bruto das Notas Entregues:&nbsp;
                                                <strong>{totalGrossWeight.get()}Kg</strong>
                                            </h3>
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                                <VirtualizedGrid 
                                    rows={data.attach(Downgraded).get()}
                                    columns={columns}
                                    options={options}
                                    title="Acompanhamento das Compras"
                                    actions={[
                                        {icon: 'settings', title: 'Mais Ações', method: (event: any, row:any)=> handleModal(event, row)}, 
                                    ]}
                                    onCellEdit={(value: any)=> handleCellEdit(value)}
                                    defaultSort={{field: 'date', direction: 'desc'}}

                                />
                            </>
                            :
                            <div className="img-container">
                                <img src={noData} alt="Não foi possível renderizar o gráfico por falta de dados." />
                                <h4>Não foi possível renderizar a tabela por falta de dados.</h4>
                            </div>
                        }
                    </main>

                </div>
            </div>
        </>
    )
}